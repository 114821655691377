import React from 'react';
import Mailchimp from 'react-mailchimp-form'

export function Footer() {
  return null; /*(
    <>
      <h2 style={{ color: '#645b9b' }}>Subscribe to our mailing list</h2>
      <Mailchimp
        className="Subscribe"
        action="https://dev.us17.list-manage.com/subscribe/post?u=1437ab815ce5e3abca42cf75f&amp;id=a5e02ba7be"
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
        ]}
        styles={{
          sendingMsg: {
            display: 'none',
          },
          successMsg: {
            color: "#00844f",
          },
          duplicateMsg: {
            color: "#c40e2e",
          },
          errorMsg: {
            color: "#c40e2e",
          }
        }}
      />
    </>
  );*/
}